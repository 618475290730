import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addVoter,
  fetchFilteredList,
} from "../../features/voterIdLookupSlice/voterIdLookupApi";
import {
  moveToPage,
  setPerPage,
  resetFilters,
  toggleColumn,
  toggleTemporaryData,
  updateFilters,
  setSort,
  setColumns,
} from "../../features/voterIdLookupSlice/voterIdLookupSlice";
import useUpdateEffect from "../../hooks/useUpdateEffect";
import useMemory from "../../hooks/useMemory";
import Table from "../../components/table/Table";
import VoterIdLookupSteps from "../../components/steps/VoterIdLookupSteps";
import TableButton from "../../components/table/TableButton";
import {
  addVoterToList,
  updateList,
} from "../../features/myListSlice/myListSlice";

const LookupTable = () => {
  const [isShowingTutorial, setIsShowingTutorial] = useState(false);

  const state = useSelector((state) => state.voterIdLookup);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
  }, []);

  // useMemory("voter-id-lookup-memory", state);

  const addedTempData = useRef(false);

  useUpdateEffect(() => {
    dispatch(fetchFilteredList({ navigate, params: { page: 1 } }));
  }, [state.sort]);

  const handleSort = ({ column, order }) => {
    dispatch(setSort({ column, order }));
  };

  const handleChangePage = (toPage) => {
    dispatch(moveToPage(toPage));
    dispatch(fetchFilteredList({ navigate }));
  };

  const handleFilters = (key, value) => dispatch(updateFilters([key, value]));

  const handleToggleColumn = (key) => dispatch(toggleColumn(key));

  const handleResetFilters = () => dispatch(resetFilters());

  const handleChangePerPage = (perPage) => dispatch(setPerPage(perPage));

  const handleSubmit = () => {
    dispatch(fetchFilteredList({ navigate, params: { page: 1 } }));
  };

  const handleStartTutorial = () => {
    setIsShowingTutorial(true);
    if (state.filteredList.length === 0) {
      addedTempData.current = true;
      dispatch(toggleTemporaryData());
    }
  };

  const handleReorder = (columns) => {
    const prevMemory = JSON.parse(
      localStorage.getItem("voter-id-lookup-memory")
    );

    localStorage.setItem(
      "voter-id-lookup-memory",
      JSON.stringify({ ...prevMemory, columns })
    );

    dispatch(setColumns(columns));
  };

  const listNames = useSelector((state) => state.myList.listNames);
  console.log('Redux listNames:', listNames);

  // Ensure we have an array to work with
  const safeListNames = Array.isArray(listNames) ? listNames : [];
  console.log('Safe listNames:', safeListNames);

  // Get stored list names safely
  let storedListNames = [];
  try {
    const stored = localStorage.getItem('listNames');
    if (stored) {
      const parsed = JSON.parse(stored);
      storedListNames = Array.isArray(parsed) ? parsed : [];
    }
  } catch (error) {
    console.error('Error parsing stored listNames:', error);
  }
  console.log('Stored listNames:', storedListNames);

  // Use Redux state as source of truth, fall back to localStorage if needed
  const finalListNames = safeListNames.length > 0 ? safeListNames : storedListNames;
  console.log('Final listNames:', finalListNames);

  return (
    <>
      <VoterIdLookupSteps
        enabled={isShowingTutorial}
        onExit={() => {
          setIsShowingTutorial(false);
          if (addedTempData.current) dispatch(toggleTemporaryData());
          addedTempData.current = false;
        }}
      />

      <Table
        state={{ ...state, data: state.filteredList }}
        onSortChange={handleSort}
        onFiltersChange={handleFilters}
        onToggleColumn={handleToggleColumn}
        onResetFilters={handleResetFilters}
        onChangePage={handleChangePage}
        onChangePerPage={handleChangePerPage}
        onSubmit={handleSubmit}
        onStartTutorial={handleStartTutorial}
        onReorder={handleReorder}
        isFocused={!isShowingTutorial}
        emptyPlaceholder={(onResetFilters) => (
          <>
            {state.status.fetchData === "success" && (
              <div className="w-full h-[200px] flex justify-center items-center flex-col gap-4">
                <p className="text-xl font-medium">No records found</p>

                {state.columns.some((c) => c.filter.value) && (
                  <TableButton onClick={onResetFilters} icon="/import.svg">
                    Reset Filters
                  </TableButton>
                )}
              </div>
            )}

            {state.status.fetchData === "idle" && (
              <div className="w-full h-[200px] flex justify-center items-center flex-col gap-4">
                <p className="text-xl font-medium">
                  Search for voters using their first and last names in the
                  boxes above.
                </p>
              </div>
            )}
          </>
        )}
        rowActions={{
          isDropdown: finalListNames.length > 1,
          triggers: [state.status.addVoter],
          state: state.status.addVoter,
          onClick: async (row) => {
            try {
              const result = await dispatch(
                addVoter({
                  navigate,
                  voter: row,
                  listName: finalListNames[0]
                })
              ).unwrap();
              
              if (result) {
                dispatch(addVoterToList({ voter: row, listName: finalListNames[0] }));
              }
            } catch (error) {
              console.error('Failed to add voter:', error);
            }
          },
          label: "Add Voter",
          dropdownTitle: null,
          options: finalListNames.map((listName) => ({
            label: listName,
            onClick: async (row) => {
              try {
                const result = await dispatch(
                  addVoter({
                    navigate,
                    voter: row,
                    listName,
                  })
                ).unwrap();
                
                if (result) {
                  dispatch(addVoterToList({ voter: row, listName }));
                }
              } catch (error) {
                console.error('Failed to add voter:', error);
              }
            },
          })),
          className: (isDisabled) =>
            isDisabled ? "text-black" : "text-cherry",
        }}
      />
    </>
  );
};

export default LookupTable;
