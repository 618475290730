import { clearAllListeners, createAsyncThunk } from "@reduxjs/toolkit";
import post from "../../utils/post";
import getAppropriateFiltersFormat from "../../utils/getAppropriateFiltersFormat";

export const fetchFilteredList = createAsyncThunk(
  "voterIdLookup/fetchFilteredList",
  async ({ navigate, params }, { getState }) => {
    const { perPage, sort, columns } = getState().voterIdLookup;

    const filters = getAppropriateFiltersFormat(columns);
    
    console.log('Sending request with filters:', filters);
    console.log('Active columns:', columns.filter(col => col.isActive));

    const page = new URLSearchParams(window.location.search).get("page") ?? 1;

    const response = await post(
      process.env.REACT_APP_VOTER_ID_LOOKUP_API_URL,
      {
        page,
        per_page: perPage,
        sort,
        filters,
        params,
      },
      navigate,
      null,
      null,
      {},
      false
    );

    console.log('Received response:', response);
    return response.data;
  }
);

export const addVoter = createAsyncThunk(
  "voterIdLookup/addVoter",
  async ({ navigate, voter, listName }, { rejectWithValue }) => {
    console.log('Adding voter:', voter, 'to list:', listName);

    try {
      const response = await post(
        process.env.REACT_APP_ADD_NEW_ROW_API_URL,
        {
          voter_id: String(voter.voter_id),
          list_name: listName || 'My List',
          row: voter  // Send the full voter data
        },
        navigate
      );

      console.log('Add voter response:', response);

      if (!response?.data?.data) {
        console.error('Invalid response format:', response);
        return rejectWithValue({ 
          voter, 
          error: 'Invalid response from server' 
        });
      }

      if (response.data.error) {
        console.error('Server returned error:', response.data.error);
        return rejectWithValue({ 
          voter, 
          error: response.data.error 
        });
      }

      return { 
        voter: response.data.data,  // Use the voter data returned from the server
        message: response.data.message 
      };
    } catch (error) {
      console.error('Error adding voter:', error);
      return rejectWithValue({ 
        voter, 
        error: error.message || 'Failed to add voter' 
      });
    }
  }
);
