import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteData,
  deleteVoter,
  edit,
  exportTable,
  fetchData,
  fetchVoters,
  moveVoter,
} from "../../features/myListSlice/myListApi";
import {
  toggleAllRows,
  moveToPage,
  updateFilters,
  setPerPage,
  toggleColumn,
  resetFilters,
  toggleTemporaryData,
  setSort,
  toggleSelectRow,
  setColumns,
  replaceVoter,
  updateListNames,
  changeActiveListName,
} from "../../features/myListSlice/myListSlice";
import useMemory from "../../hooks/useMemory";
import Table from "../../components/table/Table";
import InCompleteRowSearchResult from "./InCompleteRowSearchResult";
import AddVotersModal from "./AddVotersModal";
import TableButton from "../../components/table/TableButton";
import MyListSteps from "../../components/steps/MyListSteps";
import ConfirmDeleteData from "../../components/ConfirmDeleteData";
import Lists from "./Lists";

const MyListTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [isShowingTutorial, setIsShowingTutorial] = useState(false);
  // const [isShowingDeleteDataModal, setIsShowingDeleteDataModal] =
  //   useState(false);

  const state = useSelector((state) => state.myList);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("my-list-data"));
    const storedListNames = JSON.parse(localStorage.getItem("listNames"));
    const page = searchParams.get("page");

    console.log('Initial Load - Data from localStorage:', data);
    console.log('Initial Load - Stored List Names:', storedListNames);
    console.log('Initial Load - Current State:', state);

    // If no page parameter, set it to 1
    if (!page) {
      setSearchParams({ page: 1 });
    }

    // Extract unique list names from data if they exist
    let availableListNames = [];
    if (data && Array.isArray(data.data)) {
      availableListNames = [...new Set(data.data.map(item => item.list_name))];
      console.log('Available list names from data:', availableListNames);
    }

    // Combine stored list names with available list names
    const allListNames = [...new Set([
      ...(Array.isArray(storedListNames) ? storedListNames : []),
      ...availableListNames
    ])];

    if (allListNames.length > 0) {
      console.log('Setting list names:', allListNames);
      dispatch(updateListNames(allListNames));
      if (!state.activeListName || !allListNames.includes(state.activeListName)) {
        console.log('Setting active list name to:', allListNames[0]);
        dispatch(changeActiveListName(allListNames[0]));
      }
    } else {
      console.log('No list names found, creating default');
      const defaultList = 'My List';
      dispatch(updateListNames([defaultList]));
      dispatch(changeActiveListName(defaultList));
      localStorage.setItem('listNames', JSON.stringify([defaultList]));
    }

    // Then fetch data
    dispatch(fetchData({ navigate }));

    return () => {
      dispatch(resetFilters());
    };
  }, []);

  // Force data refresh when activeListName changes
  useEffect(() => {
    console.log('Active List Name Changed:', state.activeListName);
    console.log('Current State:', state);
    if (state.activeListName) {
      dispatch(fetchData({ navigate }));
    }
  }, [state.activeListName]);

  // useMemory("my-list-memory", state, "activeListName");

  const addedTempData = useRef(false);

  const handleSort = ({ column, order }) => {
    dispatch(setSort({ column, order }));
  };

  const handleChangePage = (toPage) => {
    dispatch(moveToPage(toPage));
  };

  const handleFilters = (key, value) => dispatch(updateFilters([key, value]));

  const handleToggleColumn = (key) => dispatch(toggleColumn(key));

  const handleResetFilters = () => dispatch(resetFilters());

  const handleChangePerPage = (perPage) => dispatch(setPerPage(perPage));

  const handleToggleSelectRow = (row) => {
    dispatch(toggleSelectRow({ id: row.id }));
  };

  const handleSelectAllRows = () => dispatch(toggleAllRows());

  const handleStartTutorial = () => {
    setIsShowingTutorial(true);
    if (state.data.length === 0) {
      addedTempData.current = true;
      dispatch(toggleTemporaryData());
    }
  };

  const handleReorder = (columns) => {
    const prevMemory = JSON.parse(localStorage.getItem("my-list-memory"));

    localStorage.setItem(
      "my-list-memory",
      JSON.stringify({ ...prevMemory, columns })
    );

    dispatch(setColumns(columns));
  };

  const handleEdit = async (row, col, value) => {
    const response = await dispatch(
      edit({
        navigate,
        email: row.email,
        id: row.id,
        attribute: col.key,
        value,
      })
    );

    if (response.type.includes("fulfilled")) {
      dispatch(
        replaceVoter({
          oldVoterId: row.id,
          newVoter: { ...row, [col.key]: value },
        })
      );
    }
  };

  return (
    <>
      <MyListSteps
        enabled={isShowingTutorial}
        onExit={() => {
          setIsShowingTutorial(false);
          if (addedTempData.current) dispatch(toggleTemporaryData());
          addedTempData.current = false;
        }}
      />

      {state.fetchedVoters && <InCompleteRowSearchResult />}

      {isImportModalOpen && (
        <AddVotersModal close={() => setIsImportModalOpen(false)} />
      )}

      {/* {isShowingDeleteDataModal && (
        <ConfirmDeleteData
          close={() => setIsShowingDeleteDataModal(false)}
          state={state.status.deleteData}
          onDelete={() => dispatch(deleteData({ navigate }))}
        />
      )} */}

      <Table
        state={{
          ...state,
          data: state.data.slice(
            (state.page - 1) * state.perPage,
            state.page * state.perPage
          ),
        }}
        onSortChange={handleSort}
        onFiltersChange={handleFilters}
        onToggleColumn={handleToggleColumn}
        onResetFilters={handleResetFilters}
        onChangePage={handleChangePage}
        onChangePerPage={handleChangePerPage}
        onToggleSelectRow={handleToggleSelectRow}
        onSelectAllRows={handleSelectAllRows}
        onStartTutorial={handleStartTutorial}
        onReorder={handleReorder}
        onEdit={handleEdit}
        emptyPlaceholder={(onResetFilters) => (
          <div className="w-full h-[200px] flex justify-center items-center flex-col gap-4">
            <p className="text-xl font-medium">No records found</p>

            {state.columns.some((c) => c.filter.value) && (
              <TableButton onClick={onResetFilters} icon="/import.svg">
                Reset Filters
              </TableButton>
            )}
          </div>
        )}
        tableActions={[
          // {
          //   label: "Clear Data",
          //   type: "danger",
          //   state: state.status.clearData,
          //   onClick: () => setIsShowingDeleteDataModal(true),
          //   condition: state.data.length > 0,
          // },
          {
            label: "Print My List",
            state: state.status.export,
            onClick: () => dispatch(exportTable({ navigate })),
          },
          {
            id: "add-voter-btn",
            label: "Add My Voter List",
            icon: "/import.svg",
            onClick: () => setIsImportModalOpen(true),
          },
          {
            label: "List",
            component: Lists,
          },
        ]}
        rowActions={{
          isDropdown: true,
          triggers: [state.status.actions],
          state: state.status.actions,
          options: [
            {
              label: "Find Voter ID",
              onClick: (row) => dispatch(fetchVoters({ navigate, row })),
              condition: (row) =>
                row.voter_id === "missing" && state.selectedRows.length < 2,
            },
            {
              label: "Move to another list",
              menu: {
                state: state.status.moveVoter,
                options: (Array.isArray(state.listNames) ? state.listNames : [])
                  .filter((listName) => listName !== state.activeListName)
                  .map((listName) => ({
                    label: listName,
                    onClick: (row) => {
                      dispatch(
                        moveVoter({
                          navigate,
                          id: row.id,
                          from_list: state.activeListName,
                          to_list: listName,
                        })
                      );
                    },
                  })),
              },
            },
            {
              label:
                state.selectedRows.length > 1
                  ? "Remove voters"
                  : "Remove voter",
              type: "danger",
              onClick: (row) => dispatch(deleteVoter({ navigate, id: row.id })),
            },
          ],
        }}
      ></Table>
    </>
  );
};

export default MyListTable;
