import { createSlice } from "@reduxjs/toolkit";
import initialState, { DEFAULT_SORT, DEFAULT_COLUMNS } from "./initialState";
import {
  addOneVoter,
  deleteVoter,
  exportTable,
  fetchData,
  fetchVoters,
  searchVoters,
  upload,
  edit,
  deleteData,
  addList,
  renameList,
  removeList,
  moveVoter,
} from "./myListApi";
import downloadAsCsv from "../../utils/downloadAsCsv";
import getAppropriateFiltersFormat from "../../utils/getAppropriateFiltersFormat";

const TEMPORARY_DATA = [
  {
    first_name: "John",
    last_name: "Doe",
    voted: "0",
    voter_id: "9999999999",
  },
];

const applyFilters = (state) => {
  const columns = getAppropriateFiltersFormat(state.columns);

  const allData = JSON.parse(localStorage.getItem("my-list-data"))?.data || [];

  const data = allData
    .filter((row) => row.list_name === state.activeListName)
    .filter((row) => {
      const istrue = Object.keys(columns).every((colKey) => {
        if (!row[colKey] && columns[colKey]) return false;

        if (!row[colKey]) return true;

        return row[colKey].toString()?.toLowerCase().includes(columns[colKey]);
      });

      return istrue;
    })
    .sort((a, b) => {
      if (!state.sort.column) return 0;

      const valueA = a[state.sort.column]?.toLowerCase();
      const valueB = b[state.sort.column]?.toLowerCase();

      if (valueA === undefined) return 1;
      if (valueB === undefined) return -1;

      if (state.sort.order === "asc") {
        return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
      } else {
        return valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
      }
    });

  return data;
};

const myListSlice = createSlice({
  name: "myList",
  initialState,
  reducers: {
    updateFilters: (state, action) => {
      const [key, newFilterValue] = action.payload;
    
      state.columns = state.columns.map((col) => {
        if (col.key !== key) return col;
        return { ...col, filter: { ...col.filter, value: newFilterValue } };
      });
    
      state.data = applyFilters(state);
      state.totalPages = Math.ceil(state.data.length / state.perPage);
      console.log('Filters Updated - Data Length:', state.data.length, 'Total Pages:', state.totalPages);
    },

    moveToPage: (state, action) => {
      state.page = action.payload;
    },

    setPerPage: (state, action) => {
      state.perPage = action.payload;
      state.totalPages = Math.ceil(state.data.length / state.perPage);
      console.log('Per Page:', state.perPage, 'Total Pages:', state.totalPages);
    },

    toggleColumn: (state, action) => {
      const key = action.payload;

      state.columns = state.columns.map((col) => {
        if (col.key === key)
          return {
            ...col,
            isActive: !col.isActive,
            filter: { ...col.filter, value: "" },
          };
        return col;
      });

      if (state.sort.column === key) state.sort = { column: null, order: null };
    },

    resetFilters: (state) => {
      localStorage.removeItem("my-list-memory");

      state.columns = DEFAULT_COLUMNS.map((col, i) => ({
        ...col,
        isActive: state.columns[i].isActive,
      }));
      state.sort = DEFAULT_SORT;
      state.data = applyFilters(state);
      state.totalPages = Math.ceil(state.data.length / state.perPage);
    },

    setSort: (state, action) => {
      state.sort = action.payload;

      state.data = applyFilters(state);
      state.totalPages = Math.ceil(state.data.length / state.perPage);
    },

    setColumns: (state, action) => {
      state.columns = action.payload;
    },

    toggleSelectRow: (state, action) => {
      const { id } = action.payload;

      if (state.selectedRows.includes(id)) {
        state.selectedRows = state.selectedRows.filter(
          (voterId) => voterId !== id
        );
      } else {
        state.selectedRows = [...state.selectedRows, id];
      }
    },

    toggleAllRows: (state) => {
      const activeData = state.data.slice(
        (state.page - 1) * state.perPage,
        state.page * state.perPage
      );

      const allRowsSelected =
        activeData.length !== 0 &&
        activeData.every((row) => state.selectedRows.includes(row.id));

      if (allRowsSelected) {
        state.selectedRows = state.selectedRows.filter((prevId) => {
          return !activeData.find((row) => row.id === prevId);
        });
      } else {
        state.selectedRows = activeData.map((row) => row.id);
      }
    },

    clearFetchedVoters: (state) => {
      state.fetchedVoters = null;
      state.fetchedFor = null;
    },

    clearSearchedVoters: (state) => {
      state.searchedVoters = null;
    },

    updateUploadStatus: (state, action) => {
      const { percent, added = 0, updated = 0, skipped = 0 } = action.payload;
      state.status.upload = percent;
      state.status.uploadStats = {
        added: (state.status.uploadStats?.added || 0) + added,
        updated: (state.status.uploadStats?.updated || 0) + updated,
        skipped: (state.status.uploadStats?.skipped || 0) + skipped,
      };
    },

    resetUploadStats: (state) => {
      state.status.uploadStats = {
        added: 0,
        updated: 0,
        skipped: 0
      };
    },

    toggleTemporaryData: (state) => {
      if (state.data.length === 0) state.data = TEMPORARY_DATA;
      else state.data = [];
    },

    changeActiveListName: (state, action) => {
      state.activeListName = action.payload;
      const data = JSON.parse(localStorage.getItem("my-list-data"));
    
      // Reset to page 1 when changing active list
      state.page = 1;
    
      state.data = data.data.filter(
        (voter) => voter.list_name === state.activeListName
      );
      state.totalPages = Math.ceil(state.data.length / state.perPage);
    
      console.log('Active List Name Changed:', state.activeListName, 'Data Length:', state.data.length, 'Total Pages:', state.totalPages);
    },

    updateListNames: (state, action) => {
      // Ensure we're storing an array
      let listNames;
      if (typeof action.payload === 'string') {
        try {
          // Handle both single and double quote string representations
          const cleanedStr = action.payload.replace(/'/g, '"');
          listNames = JSON.parse(cleanedStr);
        } catch (e) {
          console.error('Error parsing list names:', e);
          listNames = [];
        }
      } else {
        listNames = Array.isArray(action.payload) ? action.payload : [];
      }
      
      state.listNames = listNames;
      if (!state.activeListName && listNames.length > 0) {
        state.activeListName = listNames[0];
      }
      
      // Update localStorage with JSON string
      localStorage.setItem("listNames", JSON.stringify(listNames));
    },

    addVoterToList: (state, action) => {
      const { voter, listName } = action.payload;

      const data = JSON.parse(localStorage.getItem("my-list-data"));

      if (!data) return;

      const newData = [
        {
          ...voter,
          list_name: listName,
          id: voter.id || voter.voter_id.toString(),
        },
        ...data.data,
      ];

      state.data = newData.filter(
        (voter) => voter.list_name === state.activeListName
      );

      state.totalPages = Math.ceil(state.data.length / state.perPage);

      localStorage.setItem(
        "my-list-data",
        JSON.stringify({
          data: newData,
          totalPages: state.totalPages,
        })
      );
    },

    replaceVoter: (state, action) => {
      const { oldVoterId, newVoter } = action.payload;

      const data = JSON.parse(localStorage.getItem("my-list-data"));

      if (!data) return;

      const newData = data.data.map((voter) => {
        if (voter.id === oldVoterId && voter.list_name === newVoter.list_name)
          return newVoter;
        return voter;
      });

      localStorage.setItem(
        "my-list-data",
        JSON.stringify({
          data: newData,
          totalPages: data.totalPages,
        })
      );

      state.data = newData.filter(
        (voter) => voter.list_name === state.activeListName
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.status.fetchData = "loading";
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.status.fetchData = "idle";
    
        state.data =
          action.payload.data?.filter(
            (voter) => voter.list_name === state.activeListName
          ) || [];
        state.totalPages = Math.ceil(state.data.length / state.perPage);
        localStorage.setItem(
          "my-list-data",
          JSON.stringify({
            data: action.payload.data || [],
            totalPages: state.totalPages,
          })
        );
        console.log('Data fetched - Data Length:', state.data.length, 'Total Pages:', state.totalPages);
    })
      .addCase(fetchData.rejected, (state, action) => {
        if (!action.payload?.aborted) state.status.fetchData = "idle";
      })

      .addCase(deleteVoter.pending, (state) => {
        state.status.actions = "loading";
      })
      .addCase(deleteVoter.fulfilled, (state, action) => {
        state.status.actions = "idle";

        const { targetedRows } = action.payload;

        const data = JSON.parse(localStorage.getItem("my-list-data"));
        const newData = data.data.filter(
          (voter) => !targetedRows.includes(voter.id.toString())
        );

        state.data = newData.filter(
          (voter) => voter.list_name === state.activeListName
        );

        state.totalPages = Math.ceil(state.data.length / state.perPage);

        localStorage.setItem(
          "my-list-data",
          JSON.stringify({
            data: newData,
            totalPages: state.totalPages,
          })
        );

        state.selectedRows = [];
      })
      .addCase(deleteVoter.rejected, (state) => {
        state.status.actions = "idle";
      })

      .addCase(fetchVoters.pending, (state) => {
        state.status.actions = "loading";
      })
      .addCase(fetchVoters.fulfilled, (state, action) => {
        state.status.actions = "idle";
        state.fetchedVoters = action.payload.data;
        state.fetchedFor = action.payload.row;
      })
      .addCase(fetchVoters.rejected, (state) => {
        state.status.actions = "idle";
      })

      .addCase(exportTable.pending, (state) => {
        state.status.export = "loading";
      })
      .addCase(exportTable.fulfilled, (state, action) => {
        state.status.export = "idle";
        if (action.payload.data?.export_data) {
          downloadAsCsv(action.payload.data.export_data);
        }
      })
      .addCase(exportTable.rejected, (state) => {
        state.status.export = "idle";
      })

      .addCase(upload.pending, (state) => {
        state.status.upload = 0;
      })
      .addCase(upload.fulfilled, (state, action) => {
        state.status.upload = "idle";

        state.data =
          action.payload.data?.filter(
            (voter) => voter.list_name === state.activeListName
          ) || [];
        state.totalPages = Math.ceil(state.data.length / state.perPage);
        localStorage.setItem(
          "my-list-data",
          JSON.stringify({
            data: action.payload.data || [],
            totalPages: state.totalPages,
          })
        );
      })
      .addCase(upload.rejected, (state) => {
        state.status.upload = "idle";
      })

      .addCase(searchVoters.pending, (state) => {
        state.status.search = "loading";
      })
      .addCase(searchVoters.fulfilled, (state, action) => {
        state.status.search = "idle";
        state.searchedVoters = action.payload.data;
      })
      .addCase(searchVoters.rejected, (state) => {
        state.status.search = "idle";
      })

      .addCase(addOneVoter.pending, (state) => {
        state.status.addOneVoter = "loading";
      })
      .addCase(addOneVoter.fulfilled, (state) => {
        state.status.addOneVoter = "idle";
      })
      .addCase(addOneVoter.rejected, (state) => {
        state.status.addOneVoter = "idle";
      })

      .addCase(edit.pending, (state) => {
        state.status.edit = "loading";
      })
      .addCase(edit.fulfilled, (state) => {
        state.status.edit = "idle";
      })
      .addCase(edit.rejected, (state) => {
        state.status.edit = "idle";
      })

      .addCase(deleteData.pending, (state) => {
        state.status.deleteData = "loading";
      })
      .addCase(deleteData.fulfilled, (state) => {
        state.status.deleteData = "idle";

        state.data = [];
        state.totalPages = 0;

        localStorage.setItem(
          "my-list-data",
          JSON.stringify({
            data: [],
            totalPages: 0,
          })
        );
      })
      .addCase(deleteData.rejected, (state) => {
        state.status.deleteData = "idle";
      })

      .addCase(addList.pending, (state) => {
        state.status.addList = "loading";
      })
      .addCase(addList.fulfilled, (state, action) => {
        state.listNames = action.payload.listNames || [...state.listNames, action.payload.newListName];
        localStorage.setItem("listNames", JSON.stringify(state.listNames));
        state.status.addList = "idle";
      })
      .addCase(addList.rejected, (state) => {
        state.status.addList = "idle";
      })

      .addCase(renameList.pending, (state) => {
        state.status.renameList = "loading";
      })
      .addCase(renameList.fulfilled, (state) => {
        state.status.renameList = "idle";
      })
      .addCase(renameList.rejected, (state) => {
        state.status.renameList = "idle";
      })

      .addCase(removeList.pending, (state) => {
        state.status.removeList = "loading";
      })
      .addCase(removeList.fulfilled, (state, action) => {
        state.status.removeList = "idle";

        const { listName } = action.payload;

        state.listNames = state.listNames.filter((name) => name !== listName);
        localStorage.setItem("listNames", JSON.stringify(state.listNames));
      })
      .addCase(removeList.rejected, (state) => {
        state.status.removeList = "idle";
      })

      .addCase(moveVoter.pending, (state) => {
        state.status.moveVoter = "loading";
      })
      .addCase(moveVoter.fulfilled, (state, action) => {
        state.status.moveVoter = "idle";

        const data = JSON.parse(localStorage.getItem("my-list-data"));

        const newData = data.data.map((voter) => {
          if (
            voter.list_name === action.payload.from_list &&
            voter.id === action.payload.id
          ) {
            return { ...voter, list_name: action.payload.to_list };
          }

          return voter;
        });

        state.data = newData.filter(
          (voter) => voter.list_name === state.activeListName
        );

        state.totalPages = Math.ceil(state.data.length / state.perPage);

        localStorage.setItem(
          "my-list-data",
          JSON.stringify({
            data: newData,
            totalPages: state.totalPages,
          })
        );
      })
      .addCase(moveVoter.rejected, (state) => {
        state.status.moveVoter = "idle";
      });
  },
});

export const {
  updateFilters,
  moveToPage,
  setPerPage,
  toggleColumn,
  resetFilters,
  setSort,
  setColumns,
  toggleSelectRow,
  toggleAllRows,
  clearFetchedVoters,
  updateUploadStatus,
  clearSearchedVoters,
  toggleTemporaryData,
  changeActiveListName,
  updateListNames,
  updateList,
  addVoterToList,
  replaceVoter,
  resetUploadStats,
} = myListSlice.actions;

export default myListSlice.reducer;
