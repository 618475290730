import axios from "axios";
import { toast } from "react-toastify";

export default async function post(
  url,
  params = {},
  navigate = null,
  onSuccess = () => {},
  onError = () => {},
  options = {},
  showToast = true
) {
  console.log('POST Request to:', url);
  console.log('Request params:', params);
  
  try {
    const token = localStorage.getItem("jwt-token");
    const response = await axios.post(url, params, {
      ...options,
      headers: {
        ...options?.headers,
        Authorization: token ? `Bearer ${token}` : undefined,
      },
    });

    if (showToast) toast.success(response.data.message);

    if (typeof onSuccess === "function") onSuccess(response);

    console.log('POST Response:', {
      status: response.status,
      data: response.data,
      headers: response.headers
    });

    return response;
  } catch (error) {
    console.error('POST Error:', {
      message: error.message,
      response: error.response?.data,
      status: error.response?.status,
      config: error.config
    });
    
    if (axios.isCancel(error)) return { aborted: true };

    if (error.response) {
      if (error.response?.status === 401) {
        // Do not show toast here, handled in handleSubmit
      } else if (showToast) {
        toast.error(error.response?.data.error || "An error occurred");
      }

      if (typeof onError === "function") onError(error);

      return error.response;
    } else if (error.request) {
      toast.error(
        "Take a picture of your screen with your phone and send it to support@fireduptovote.com and mention what was happening so we can help you."
      );
    } else {
      console.error("Error:", error.message);
    }
  }
}