export default function getAppropriateFiltersFormat(columns) {
  const filters = {};

  columns.forEach((col) => {
    // Only add filter if it has a non-empty value
    if (col.filter.value) {
      console.log(`Processing filter for ${col.key}:`, {
        value: col.filter.value,
        type: typeof col.filter.value
      });
      
      if (col.key === "voted")
        filters[col.key] = col.filter.value === "Voted" ? "1" : "0";
      else 
        filters[col.key] = col.filter.value.toString().toLowerCase().trim();
    }
  });

  console.log("Final filters object:", filters);
  return filters;
}
