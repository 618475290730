import { createAsyncThunk } from "@reduxjs/toolkit";
import post from "../../utils/post";
import getAppropriateFiltersFormat from "../../utils/getAppropriateFiltersFormat";
import { updateUploadStatus, resetUploadStats } from "./myListSlice";
import { toast } from "react-toastify";

export const fetchData = createAsyncThunk(
  "myList/fetchData",
  async ({ navigate, options }, { getState, rejectWithValue }) => {
    const { sort, columns, activeListName } = getState().myList;

    const filters = getAppropriateFiltersFormat(columns);

    const page = new URLSearchParams(window.location.search).get("page") ?? 1;

    const response = await post(
      process.env.REACT_APP_LOAD_DATA_TABLE_API_URL,
      {
        purpose: "fetch_all_data",
        page,
        sort,
        filters,
        list_name: activeListName
      },
      navigate,
      null,
      null,
      options,
      false
    );

    if (response?.aborted) return rejectWithValue({ aborted: true });

    return response.data;
  }
);

export const deleteVoter = createAsyncThunk(
  "myList/deleteVoter",
  async ({ navigate, id }, { getState }) => {
    const { page, perPage, sort, columns, selectedRows } = getState().myList;

    const filters = getAppropriateFiltersFormat(columns);

    let targetedRows = [id];
    if (selectedRows.includes(id)) targetedRows = selectedRows;

    const response = await post(
      process.env.REACT_APP_LOAD_DATA_TABLE_API_URL,
      {
        purpose: "delete_voter",
        page: page,
        per_page: 99999,
        sort: sort,
        filters: filters,
        id_list: targetedRows,
      },
      navigate
    );

    return { ...response.data, targetedRows };
  }
);

export const fetchVoters = createAsyncThunk(
  "myList/fetchVoters",
  async ({ navigate, row }) => {
    const response = await post(
      process.env.REACT_APP_EMAIL_SIGNUP_API_URL,
      {
        purpose: "submit_name",
        first_name: row.first_name || "",
        last_name: row.last_name || "",
      },
      navigate
    );

    return { row, ...response.data };
  }
);

export const exportTable = createAsyncThunk(
  "myList/exportTable",
  async ({ navigate }) => {
    const response = await post(
      process.env.REACT_APP_LOAD_DATA_TABLE_API_URL,
      { purpose: "export" },
      navigate
    );

    return response.data;
  }
);

export const upload = createAsyncThunk(
  "myList/upload",
  async ({ navigate, data, listName }, { getState, dispatch }) => {
    const CHUNK_SIZE = 25;
    const { page, sort, columns } = getState().myList;
    const filters = getAppropriateFiltersFormat(columns);
    
    // Filter out unwanted columns from each record
    const cleanData = data.map(record => {
      const { 'List Name': _, 'Missing Info': __, ...cleanRecord } = record;
      return cleanRecord;
    });
    
    const totalChunks = Math.ceil(cleanData.length / CHUNK_SIZE);
    let processedChunks = 0;

    // Reset upload stats at the start
    dispatch(resetUploadStats());

    for (let i = 0; i < totalChunks; i++) {
      console.log(`Uploading chunk ${i + 1} of ${totalChunks}`);
      const chunk = cleanData.slice(i * CHUNK_SIZE, (i + 1) * CHUNK_SIZE);

      try {
        const response = await post(
          process.env.REACT_APP_LOAD_DATA_TABLE_API_URL,
          {
            data: chunk,
            purpose: "import_data",
            page,
            per_page: 99999,
            sort,
            filters,
            total_rows: cleanData.length,
            chunk_number: i + 1,
            total_chunks: totalChunks,
            list_name: listName,
          },
          navigate,
          null,
          null,
          {},
          false
        );

        if (!response.data || response.data.error) {
          const errorMsg = response.data?.error || 'Failed to upload chunk';
          console.error(`Error on chunk ${i + 1}:`, errorMsg);
          toast.error(`Upload failed: ${errorMsg}`);
          throw new Error(errorMsg);
        }

        console.log(`Response for chunk ${i + 1}:`, response);
        processedChunks++;
        const progress = Math.round((processedChunks / totalChunks) * 100);
        console.log(`Progress: ${progress}%`);
        
        // Extract statistics from response
        const added = response.data.added_count || 0;
        const updated = response.data.updated_count || 0;
        const skipped = response.data.skipped_count || 0;

        // Update progress and statistics
        dispatch(updateUploadStatus({ 
          percent: progress,
          added,
          updated,
          skipped
        }));

        if (i === totalChunks - 1) {
          const stats = getState().myList.status.uploadStats;
          const totalMsg = `Upload completed successfully. Added ${stats.added} records, updated ${stats.updated} records, skipped ${stats.skipped} records.`;
          toast.success(totalMsg);
          return response.data;
        }
      } catch (error) {
        console.error(`Error on chunk ${i + 1}:`, error);
        toast.error(`Upload failed on chunk ${i + 1}: ${error.message || 'Unknown error'}`);
        throw error; // Stop the upload on any error
      }
    }
  }
);

export const searchVoters = createAsyncThunk(
  "myList/searchVoters",
  async ({ navigate, formData }) => {
    const { first_name, middle_name, last_name } = formData;

    const response = await post(
      process.env.REACT_APP_EMAIL_SIGNUP_API_URL,
      {
        purpose: "submit_name",
        first_name,
        middle_name,
        last_name,
      },
      navigate
    );

    return response.data;
  }
);

export const addOneVoter = createAsyncThunk(
  "myList/addOneVoter",
  async ({ navigate, params }, { getState }) => {
    const { page, perPage, sort, columns } = getState().myList;

    const filters = getAppropriateFiltersFormat(columns);

    const response = await post(
      process.env.REACT_APP_ADD_NEW_ROW_API_URL,
      {
        purpose: "manual_data_add",
        page,
        per_page: 99999,
        sort,
        filters,
        ...params,
      },
      navigate
    );

    return response.data;
  }
);

export const edit = createAsyncThunk(
  "myList/edit",
  async ({ navigate, email, id, attribute, value }, { getState }) => {
    const { page, activeListName, sort, columns } = getState().myList;

    const filters = getAppropriateFiltersFormat(columns);

    console.log({
      email,
      id,
      attribute,
      value,
      table: "UserVoters",
      list_name: activeListName,
      page,
      per_page: 99999,
      sort,
      filters,
    });

    const response = await post(
      process.env.REACT_APP_MODIFY_VALUE_API_URL,
      {
        email,
        id,
        attribute,
        value,
        table: "UserVoters",
        list_name: activeListName,
        page,
        per_page: 99999,
        sort,
        filters,
      },
      navigate
    );

    return response.data;
  }
);

export const deleteData = createAsyncThunk(
  "myList/deleteData",
  async ({ navigate }) => {
    const email = localStorage.getItem("email");

    if (!email) {
      navigate("/login");
      localStorage.clear();
    }

    const response = await post(
      process.env.REACT_APP_ADMIN_API_URL,
      {
        purpose: "delete_user_voters_data",
        email,
      },
      navigate
    );

    return response.data;
  }
);

export const addList = createAsyncThunk(
  "myList/addList",
  async ({ navigate, newListName }) => {
    const email = localStorage.getItem("email");
    
    if (!email) {
      navigate("/login");
      localStorage.clear();
      throw new Error("No email found");
    }

    const response = await post(
      process.env.REACT_APP_MULTI_LISTS_API_URL,
      {
        purpose: "add_list",
        new_list_name: newListName
      },
      navigate,
      null,
      null,
      {},
      false // Don't show automatic toast
    );

    // Check if we have a successful response with data
    if (response.data?.data?.listNames) {
      return { 
        newListName,
        listNames: response.data.data.listNames
      };
    }

    // If we don't have listNames in the response, throw an error
    throw new Error(response.data?.message || "Failed to add list");
  }
);

export const renameList = createAsyncThunk(
  "myList/renameList",
  async ({ navigate, oldName, newName }) => {
    const response = await post(
      process.env.REACT_APP_MULTI_LISTS_API_URL,
      {
        purpose: "modify_list_name",
        old_name: oldName,
        new_name: newName,
      },
      navigate
    );

    return response.data;
  }
);
export const removeList = createAsyncThunk(
  "myList/removeList",
  async ({ navigate, listName }) => {
    const response = await post(
      process.env.REACT_APP_MULTI_LISTS_API_URL,
      {
        purpose: "remove_list",
        list_name: listName,
      },
      navigate
    );

    return { listName };
  }
);

export const moveVoter = createAsyncThunk(
  "myList/moveVoter",
  async ({ navigate, id, from_list, to_list }) => {
    const response = await post(
      process.env.REACT_APP_MULTI_LISTS_API_URL,
      {
        purpose: "move_voter",
        id,
        from_list,
        to_list,
      },
      navigate
    );

    return { from_list, to_list, id, ...response.data };
  }
);
